<script setup>
import {computed, onMounted, ref, watch} from 'vue';
import InfoCircleIcon from '../../Icons/InfoCircleIcon.vue';
import {snakeCase} from 'lodash-es';
import FormInput from './FormInput.vue';
import ErrorMessage from '../../Input/ErrorMessage.vue';

const props = defineProps({
    modelValue: [String, Number],
    infoText: {type: String, required: false},
    label: {type: String, required: false},
    placeholder: {type: String, required: false},
    type: {type: String, default: 'text', required: false},
    inlineInfoText: {type: Boolean, default: false, required: false},
    disabled: {type: Boolean, default: false},
    error: {type: String, required: false},
    min: {type: Number, required: false},
    max: {type: Number, required: false},
    step: {type: Number, required: false},
    name: {type: String, required: false},
});

defineEmits(['update:modelValue']);

const input = ref(null);
const computedLabel = computed(() => snakeCase(props.label));
const hasError = ref(false);

onMounted(() => {
    hasError.value = Boolean(props.error?.length);
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }
});

watch(() => props.error, () => {
    hasError.value = Boolean(props.error?.length);
});

const onBlur = () => {
    if (hasError.value) {
        hasError.value = false;
    }
};

defineExpose({focus: () => input.value.focus()});
</script>

<template>
    <form-input :id="computedLabel" :class="{'disabled-inputs': disabled, 'error': hasError}" :label="label">
        <template v-if="infoText && !inlineInfoText" #after-label>
            <info-circle-icon :infoText="infoText"/>
        </template>
        <div :class="{'inline-info': inlineInfoText}" class="text-input flex flex-g1">
            <input
                ref="input"
                :id="computedLabel"
                :name="name || computedLabel"
                :disabled="disabled"
                :placeholder="$t((placeholder !== undefined ? placeholder : label) || '')"
                :type="type"
                :min="min"
                :max="max"
                :step="step"
                :value="modelValue"
                class="text-input"
                v-bind="$attrs"
                @blur="onBlur"
                @input="$emit('update:modelValue', $event.target.value)"
            >
            <div v-if="inlineInfoText" class="inline-info">
                <info-circle-icon :infoText="infoText"/>
            </div>
        </div>
        <error-message v-if="hasError" :error="error"/>
    </form-input>
</template>


<style lang="scss">
.text-input {
    padding: 0;

    input {
        font-family: "Brandon Text";
        border-radius: var(--border-radius-sm);
        background: var(--primary);
        border: 1px solid var(--text-inactive);
        width: 100%;
        outline: transparent;
        color: var(--text-primary);
        padding: 1.3rem 1.6rem;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;

        &::placeholder {
            color: var(--text-secondary);
        }
    }

    &.inline-info {
        position: relative;

        div.inline-info {
            position: absolute;
            top: 0;
            right: 2rem;
            bottom: 0;
            margin-block: auto;

            .info-circle {
                svg {
                    path {
                        fill: var(--label);
                    }
                }
            }
        }
    }
}
</style>
